import {
  createSSRApp,
  createApp as createClientApp,
  h,
  reactive,
  markRaw,
} from 'vue'
import { createPinia } from 'pinia'
import { StoryblokVue, apiPlugin } from '@storyblok/vue'
import { LazyHydrationWrapper } from 'vue3-lazy-hydration'
import App from './app.vue'
import Page from './pages/Page.vue'
import { setPageContext } from './renderer/usePageContext'

export { createApp }

function createApp(pageContext, clientOnly) {
  let rootComponent = pageContext
  const PageWithLayout = {
    data: () => ({
      Page: markRaw(!clientOnly ? pageContext.Page : Page),
      pageProps: markRaw(pageContext.pageProps || {}),
    }),
    render() {
      return h(
        App,
        {},
        {
          default() {
            return h(
              !clientOnly ? rootComponent.Page : Page,
              rootComponent.pageProps || {},
            )
          },
        },
      )
    },
    created() {
      rootComponent = this
    },
  }

  const app = clientOnly
    ? createClientApp(PageWithLayout)
    : createSSRApp(PageWithLayout)

  if (!import.meta.env.VITE_IS_EDITOR) {
    // This lazy hydration only works on first page load, on client side navigation everything is hydrated
    app.component('LazyHydrate', LazyHydrationWrapper)
  } else {
    // In the visual editor everything is always hydrated,
    // so we replace this wrapper with a dummy
    app.component('LazyHydrate', {
      inheritAttrs: false,
      emits: ['hydrated'],
      mounted() {
        this.$emit('hydrated')
      },
      render() {
        // Use display:contents so this wrapper doesn't interfere with the layout
        return h(
          'div',
          {
            class: 'editor-only-wrapper use child div in custom css rules',
            style: { display: 'contents' },
          },
          this.$slots.default(),
        )
      },
    })
  }

  const store = createPinia()
  app.use(store)

  app.use(StoryblokVue, {
    accessToken: import.meta.env.VITE_STORYBLOK_ACCESS_TOKEN,
    apiOptions: {
      // storyblok-js-client config object
      cache: {
        type: 'none',
      },
    },
    bridge: !!import.meta.env.VITE_IS_EDITOR,
    use: [apiPlugin],
  })

  // We use `app.changePage()` to do Client Routing, see `_default.page.client.js`
  Object.assign(app, {
    changePage: (pageContext) => {
      Object.assign(pageContextReactive, pageContext)
      rootComponent.pageProps = markRaw(pageContext.pageProps || {})
    },
  })

  // When doing Client Routing, we mutate pageContext (see usage of `app.changePage()` in `_default.page.client.js`).
  // We therefore use a reactive pageContext.
  const pageContextReactive = reactive(pageContext)

  setPageContext(app, pageContextReactive)

  return { app, store }
}
