// Each favorite has this structure, to make it backwards compatible
// {
//   "id": 4477,
//   "title": "Programmeur Java",
//   "organization": "JRPL",
//   "vacancySlug": "programmeur-java",
//   "companySlug": "jrpl"
// }
export const getFavorites = () => {
  const favorites = []

  const userDataStr = localStorage.getItem('user')
  if (userDataStr) {
    const data = JSON.parse(userDataStr)
    return data?.user?.favorites || []
  }

  return favorites
}

export const saveFavorites = (favorites) => {
  let data = { user: {} }
  const userDataStr = localStorage.getItem('user')
  if (userDataStr) {
    data = JSON.parse(userDataStr)
    if (!data.user) {
      data.user = {}
    }
  }
  data.user.favorites = favorites
  localStorage.setItem('user', JSON.stringify(data))
}
