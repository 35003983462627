export const pickPublicationScope = (vacancy) => {
  let publication

  vacancy.publicationScopes.forEach((pub) => {
    if (pub.scope === 'internal') {
      publication = pub
    } else if (pub.scope === 'regional' && publication?.scope !== 'internal') {
      publication = pub
    } else if (!publication) {
      publication = pub
    }
  })

  return publication
}
