import { makeRequest } from './helper.js'
const API_PATH = '/content-delivery/v1'

const fetchVacancy = async ({ vacancyId, domainSlug, baseTooUrl }) => {
  const url = `${baseTooUrl}${API_PATH}/${domainSlug}/vacancies/${vacancyId}`
  const requestOptions = {
    method: 'GET',
  }

  const { data } = await makeRequest({ url, requestOptions })
  return data
}

const fetchVacancyList = ({ searchParams, page, domainSlug, baseTooUrl }) => {
  const url = new URL(`${baseTooUrl}${API_PATH}/${domainSlug}/search/vacancies`)
  const queryParams = new URLSearchParams(page)
  const body = JSON.stringify(searchParams)
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  }

  return makeRequest({ url: `${url}?${queryParams}`, requestOptions })
}

const fetchCompany = async ({ companySlug, domainSlug, baseTooUrl }) => {
  const url = `${baseTooUrl}${API_PATH}/${domainSlug}/companies/${companySlug}`
  const requestOptions = {
    method: 'GET',
  }

  const { data } = await makeRequest({ url, requestOptions })
  return data
}

const fetchTagList = async ({ domainSlug, baseTooUrl }) => {
  const url = `${baseTooUrl}${API_PATH}/${domainSlug}/tags`
  const requestOptions = {
    method: 'GET',
  }

  const { data } = await makeRequest({ url, requestOptions })
  return data
}

// if useFallback is false, this will simply return the requested site as usual
// useFallback = true; will return a fallback site 'www.local-dev.io' if the one requested doesn't exist. It will also fill in any missing properties in requested site
// its main purpose is to be used in the context of the visual editor, where the first folder is not a valid site slug but an arbitrary slug
// The fallback site should have everything needed configured correctly. In particular, the theme
const fetchSite = async ({ domainSlug, baseTooUrl, useFallback }) => {
  const fallbackDomainSlug = 'www-local-dev-io'
  const shouldUseFallback = useFallback && fallbackDomainSlug !== domainSlug
  const url = `${baseTooUrl}${API_PATH}/${domainSlug}/sites`
  const requestOptions = {
    method: 'GET',
  }
  let fallbackSite = {}

  if (shouldUseFallback) {
    fallbackSite = await fetchSite({
      domainSlug: fallbackDomainSlug,
      baseTooUrl,
    })
  }

  try {
    const { data } = await makeRequest({ url, requestOptions })

    let site = data

    // let all missing properties in site have a fallback
    if (shouldUseFallback) {
      site = { ...fallbackSite, ...site }
    }

    // theme is provided as a stringified JSON, so we convert it to a JS object
    if (site.theme && typeof site.theme === 'string') {
      site.theme = JSON.parse(site.theme)
    }

    return site
  } catch (err) {
    // if site requested doesn't exist and fallback is enabled, return fallback site instead
    if (useFallback && err.message === '404') {
      return fallbackSite
    } else {
      throw err
    }
  }
}

export { fetchVacancy, fetchVacancyList, fetchCompany, fetchTagList, fetchSite }
