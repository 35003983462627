<template>
  <!-- We add this here so ESLint doesn't complain because the template is empty -->
  <noscript />
</template>

<script setup>
import { useHead } from '@unhead/vue'
import { usePageContext } from '../renderer/usePageContext'
import { useMainStore } from '../store'
import { getGoogleFontLinks, getCustomFontStyle } from '../utils/fontUtils'

const props = defineProps({
  site: {
    type: Object,
    default: () => {},
  },
})

const externalFontLinks = []
const localFonts = []

const store = useMainStore()

const processStoryblokThemeFonts = () => {
  store.theme.fontSourceFiles.forEach((font) => {
    if (font.data === undefined) {
      return
    }
    if (font.data.fontType === 'customFont' && font.data.localFonts.length) {
      localFonts.push(
        getCustomFontStyle(
          font.name,
          font.data.localFonts,
          usePageContext().nonce,
        ),
      )
    } else {
      const fontName = font.data.googleFont
        ? font.data.googleFont.family
        : font.name
      externalFontLinks.push(
        ...getGoogleFontLinks(fontName, font.googleFontVariant),
      )
    }
  })
}
const processPublisherThemeFonts = () => {
  const themeFontKeys = ['--content', '--heading', '--subheading']
  const usedFonts = props.site?.theme
    ? [
        // use a set for cases where content and heading use the same font, to avoid loading the same font twice
        ...new Set(
          themeFontKeys
            .filter((key) => key in props.site.theme)
            .map((key) => props.site.theme[key]),
        ),
      ]
    : []
  usedFonts.forEach((fontName) => {
    externalFontLinks.push(...getGoogleFontLinks(fontName))
  })
}

if (store.theme) {
  processStoryblokThemeFonts()
} else if (props.site) {
  processPublisherThemeFonts()
}

if (localFonts.length) {
  externalFontLinks.push({
    rel: 'preconnect',
    href: 'https://s3.amazonaws.com',
    crossorigin: true,
  })
}

useHead(
  {
    link: [
      { rel: 'preconnect', href: 'https://fonts.googleapis.com' },
      {
        rel: 'preconnect',
        href: 'https://fonts.gstatic.com',
        crossorigin: true,
      },
      ...externalFontLinks,
    ],
    style: [...localFonts],
  },
  { mode: import.meta.env.VITE_IS_EDITOR ? 'client' : 'server' },
)
</script>
