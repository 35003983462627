<template>
  <div
    aria-live="assertive"
    class="pointer-events-none fixed inset-0 z-999 flex items-end px-4 py-6 sm:items-start sm:p-6"
  >
    <div
      class="hidden w-full flex-col items-center space-y-4 sm:items-end lg:flex"
    >
      <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
      <transition
        enter-active-class="transform ease-out duration-300 transition"
        enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div
          v-if="show"
          class="pointer-events-auto w-full max-w-xs overflow-hidden rounded-lg border-2 border-dashed border-cyan-500 bg-slate-50/80 shadow-lg"
        >
          <div class="p-3">
            <div class="flex items-start">
              <div class="shrink-0">
                <InformationCircleIcon
                  class="h-6 w-6 text-cyan-600"
                  aria-hidden="true"
                />
              </div>
              <div class="ml-3 w-0 flex-1 pt-0.5">
                <p class="font-base text-xs text-cyan-700">
                  Context of the page:
                </p>
                <template v-if="context.company">
                  <p class="mt-2 text-xs font-bold text-cyan-700">Company</p>
                  <p class="mt-0.5 text-xs text-gray-800">
                    <a
                      :href="`${publisherUrl}/companies/${context.company.id}/company`"
                      target="_blank"
                      class="underline"
                      >{{ context.company.id }}</a
                    >
                    {{ context.company.name }}
                  </p>
                </template>
                <template v-if="context.vacancy">
                  <p class="mt-2 text-xs font-bold text-cyan-700">Vacancy</p>
                  <p class="mt-0.5 text-xs text-gray-800">
                    <a
                      :href="`${publisherUrl}/vacancies/${context.vacancy.id}/vacancy`"
                      target="_blank"
                      class="underline"
                      >{{ context.vacancy.id }}</a
                    >
                    {{ context.vacancy.title }}
                  </p>
                </template>
              </div>

              <div class="ml-4 flex shrink-0">
                <button
                  type="button"
                  class="inline-flex rounded-md text-gray-600 hover:text-gray-700 focus:outline-2 focus:outline-offset-2 focus:outline-cyan-500"
                  @click="show = false"
                >
                  <span class="sr-only">Close</span>
                  <XMarkIcon class="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { InformationCircleIcon } from '@heroicons/vue/24/outline'
import { XMarkIcon } from '@heroicons/vue/20/solid'

const props = defineProps({
  context: {
    type: Object,
    default: () => {},
  },
})

const show = ref(props.context.vacancy || props.context.company)
const publisherUrl = import.meta.env.VITE_PUBLISHER_BASE_URL
</script>
