<template>
  <FontsLoader :site="site" />
  <slot />
</template>

<script setup>
import { storeToRefs } from 'pinia'
import { useHead } from '@unhead/vue'
import parse from 'html-dom-parser'
import { useMainStore } from './store'
import { getImgSrc } from './utils/imgUtils'
import { usePageContext } from './renderer/usePageContext'

import FontsLoader from './components/FontsLoader.vue'

const store = useMainStore()
const { site } = storeToRefs(store)

let bodyStyle = ':root {'
for (const [key, value] of Object.entries(store.themeVariables)) {
  bodyStyle += `${key}: ${value};`
}
bodyStyle += '}'

let themeColorClasses = ''
store.theme?.palette?.forEach((color) => {
  // For each of the colors defined in _theme we add the following classes using the naming convention of Tailwind
  themeColorClasses += ` .bg-${color.name} { background-color: var(--${color.name}); }`
  themeColorClasses += ` .text-${color.name} { color: var(--${color.name}); }`
  themeColorClasses += ` .border-${color.name} { border-color: var(--${color.name}); }`
  // We also add classes used for coloring links and list items
  themeColorClasses += ` .list-${color.name}.prose ul > li::before { background-color: var(--${color.name}); }`
  themeColorClasses += ` .richtext-link.${color.name} a { color: var(--${color.name}); }`
  themeColorClasses += ` .richtext-link.${color.name} a:hover { color: color-mix(in oklab, var(--${color.name}), black 15%); }`
  themeColorClasses += ` .focus-${color.name}:focus { outline-color: var(--${color.name}); }`
})

const head = {
  htmlAttrs: {
    lang: 'nl',
  },
  script: [],
  noscript: [],
  // Inject theme variables
  style: [
    {
      key: 'themeVariables',
      nonce: usePageContext().nonce,
      innerHTML: bodyStyle,
      tagPriority: 'critical',
    },
    {
      key: 'themeColorClasses',
      nonce: usePageContext().nonce,
      innerHTML: themeColorClasses,
      tagPriority: 'critical',
    },
  ],
  link: [
    {
      rel: 'icon',
      href: getImgSrc({
        imgUrl: site.value?.faviconUrl,
        height: 32,
      }),
    },
  ],
}

// Inject site's custom header and footer code
const document = parse(
  `<head>${site.value?.customHeaderCode || ''}</head><body>${
    site.value?.customFooterCode || ''
  }</body>`,
)

const addTagsToHtml = (tags, placeInBody) => {
  tags.forEach((tag) => {
    if (tag.type !== 'text') {
      const props = { ...tag.attribs }

      if (['script', 'style'].includes(tag.name)) {
        props.nonce = usePageContext().nonce
      }

      if (placeInBody) {
        props.tagPosition = 'bodyClose'
      }
      if (tag.children.length) {
        props.innerHTML = tag.children[0].data
      }

      if (head[tag.name] === undefined) {
        head[tag.name] = []
      }
      head[tag.name].push(props)
    }
  })
}

addTagsToHtml(document[0].children) // Head
addTagsToHtml(document[1].children, true) // Body

useHead(head, { mode: import.meta.env.VITE_IS_EDITOR ? 'client' : 'server' })

// Inject GTM script if it's enabled for the site
if (import.meta.env.PROD && site.value?.googleTagManagerId) {
  useHead({
    noscript: [
        {
          innerHTML: `<iframe src="https://www.googletagmanager.com/ns.html?id=${site.value.googleTagManagerId}"
height="0" width="0" class="hidden"></iframe>`,
          tagPosition: 'bodyOpen',
        },
      ],
    },
    { mode: 'server' },
  )

  useHead(
    {
      script: [
        {
          id: 'gtmScript',
          nonce: usePageContext().nonce,
          innerHTML: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;var n=d.querySelector('[nonce]');
n&&j.setAttribute('nonce',n.nonce||n.getAttribute('nonce'));f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${site.value.googleTagManagerId}');`,
        },
      ],
    },
    { mode: 'client' },
  )
}
</script>
