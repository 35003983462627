<template>
  <div class="antialiased">
    <PageCheck v-if="isVisualEditor" :bloks="content.blocks" />

    <!-- HEADER SECTION BEGIN -->
    <template v-if="siteHeader">
      <a
        href="#mainContent"
        class="bg-default absolute -top-96 z-50 rounded-br-lg px-2 py-1 font-medium text-white focus:top-0"
      >
        {{ skipNavigationLabel }}
      </a>
      <PageBlock :block="siteHeader" :context="context" />
    </template>
    <!-- HEADER SECTION END -->

    <main id="mainContent">
      <PageBlock
        v-for="currentBlok in visibleBlocks"
        :key="currentBlok._uid"
        :block="currentBlok"
        :context="context"
      />
    </main>

    <!-- FOOTER SECTION BEGIN -->
    <PageBlock v-if="siteFooter" :block="siteFooter" :context="context" />
    <!-- FOOTER SECTION END -->

    <PageMetadataOverview v-if="isVisualEditor" :context="context" />
  </div>
</template>

<script setup>
import PageCheck from '../components/PageCheck.vue'
import PageBlock from '../components/PageBlock.vue'

import { ref, computed, onMounted } from 'vue'
import { useStoryblokBridge } from '@storyblok/vue'
import { useHead, useSeoMeta } from '@unhead/vue'
import { storeToRefs } from 'pinia'
import { useMainStore } from '../store'
import PageMetadataOverview from '../components/PageMetadataOverview.vue'
import { getValidImageSrc } from '../utils/imgUtils'
import hydrateObject from '../utils/hydrateObject.js'

const store = useMainStore()
const { pageContent, pageContext, vacancy, company, publication, site } =
  storeToRefs(store)

const isVisualEditor = import.meta.env.VITE_IS_EDITOR
const editorContent = ref(pageContent)

const visibleBlocks = computed(() => {
  return content.value.blocks.filter(
    (item) =>
      item._uid !== siteHeader.value?._uid &&
      item._uid !== siteFooter.value?._uid,
  )
})
const context = computed(() => {
  const contextToUse = {
    ...pageContext.value,
    vacancy: vacancy.value,
    company: company.value,
    publication: publication.value,
    site: site.value,
    // TODO Add user when it's available
  }

  return {
    ...contextToUse,
    // This prop was renamed but we keep the old one to be backwards compatible,
    // otherwise we have to change old pages on Storyblok that were using it.
    publications: contextToUse.publication,
    currentYear: new Date().getFullYear(),
  }
})
const content = computed(() => {
  let content
  if (isVisualEditor) {
    content = editorContent.value
  } else {
    content = pageContent.value || { blocks: [] }
  }
  let blocks = content.blocks || []

  // Remove expired vacancy modal if it's present and we're not on editor mode and the vacancy is not expired
  // This is needed so Indeed doesn't mark our vacancies as closed
  // Extremely hacky solution, an alternative would be to implement an XML feed of our vacancies
  // for Indeed: https://developer.indeed.com/docs/indeed-apply/direct-employer/
  if (!isVisualEditor && context.value.publication) {
    const vacancyExpired =
      Date.now() > Date.parse(context.value.publication?.end)

    if (!vacancyExpired) {
      blocks = blocks.filter(
        (block) => block.component !== 'ExpiredVacancyTextWithCallToAction',
      )
    }
  }

  // Replace placeholders in content (e.g. {{vacancy.title}}) by value from context (look at replaceBlock.spec.js)
  // TODO refactor getReplacedBlock to have content and context as args, instead of factory function
  let result = { ...content, blocks }
  return hydrateObject(result, context.value, isVisualEditor)
})
const siteHeader = computed(() => {
  return content.value.blocks.find(
    (item) => item.component === 'PageHeaderWithMenu',
  )
})
const siteFooter = computed(() => {
  return [...content.value.blocks]
    .reverse()
    .find((item) => item.component.startsWith('Footer'))
})
const skipNavigationLabel = computed(() => {
  return siteHeader.value?.skipNavigationLabel || 'Ga direct naar de content'
})

const metadata = {
  ...content.value,
}

const imgSourceTypes = []
metadata.openGraphImage && imgSourceTypes.push(metadata.openGraphImage)
metadata.openGraphImageUrl && imgSourceTypes.push(metadata.openGraphImageUrl)
metadata.image = getValidImageSrc(imgSourceTypes)

const seoMeta = {}
if (metadata.title) {
  seoMeta.twitterCard = 'summary'
  seoMeta.twitterTitle = metadata.title
  seoMeta.ogTitle = metadata.title

  useHead(
    {
      title: metadata.title,
    },
    { mode: import.meta.env.VITE_IS_EDITOR ? 'client' : 'server' },
  )
}
if (metadata.description) {
  seoMeta.description = metadata.description
  seoMeta.twitterDescription = metadata.description
  seoMeta.ogDescription = metadata.description
}
if (metadata.blockIndexing) {
  seoMeta.robots = 'noindex'
}
if (metadata.openGraphType) {
  seoMeta.ogType = metadata.openGraphType
}
if (metadata.image) {
  seoMeta.ogImage = metadata.image
}

// Execute this onlye on the server, so we can minimize client bundle
if (import.meta.env.SSR && seoMeta) {
  useSeoMeta(seoMeta)
}

if (isVisualEditor) {
  onMounted(() => {
    useStoryblokBridge(editorContent.value.id, (story) => {
      console.log('Updating visual editor!')

      // We replace the GlobalReferences with the blocks we already had on page load
      // (this 'story' object doesn't contain the referenced block data)
      const blocks = []
      story.content.blocks.forEach((block) => {
        if (block.component === 'GlobalReference') {
          const referencedBlocks = editorContent.value.blocks.filter(
            (previousBlock) => previousBlock.reference === block._uid,
          )
          blocks.push(...referencedBlocks)
        } else {
          blocks.push(block)
        }
      })

      editorContent.value = { ...story.content, blocks }
    })
  })
} else {
  onMounted(() => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'page_view', // Standard GA4 event name
      page_location: window.location.href, // Full URL of the current page
      page_referrer: document.referrer, // Full URL of the previous page
      page_path: window.location.pathname, // Path without domain
      page_title: document.title, // Title of the page
    })
  })
}
</script>
