export const getVacancyIdFromPath = (path, vacancyUrlFormat) => {
  if (vacancyUrlFormat) {
    const vacancyUrlTemplate = new URL(vacancyUrlFormat)
    // We only care about the path
    const templatePath = decodeURI(vacancyUrlTemplate.pathname)
    // We check the url path up until the position of the vacancy id
    const position = templatePath.indexOf('{{vacancy.id}}')
    if (position > -1) {
      const pathPrefix = templatePath.substring(
        0,
        position + '{{vacancy.id}}'.length,
      )
      // Replace all placeholders except {{vacancy.id}} with a regex wildcard
      const regexPattern = pathPrefix.replace(/{{(?!vacancy\.id).*?}}/g, '.*?')

      // Replace {{vacancy.id}} with a capturing group for numeric IDs
      const finalPattern = regexPattern.replace('{{vacancy.id}}', '(\\d+)')

      // Create a RegExp object
      const regex = new RegExp(finalPattern)

      // Match the URL to extract the vacancy ID
      const match = path.match(regex)
      return match ? match[1] : null
    } else {
      return null
    }
  } else {
    // We use the dumb approach: extract number from url
    const captureNumericId = /\/(\d+)(\/|$)/
    const match = path.match(captureNumericId)
    return match ? match[1] : null
  }
}

export const getCompanySlugFromPath = (path, companyUrlFormat) => {
  if (!companyUrlFormat) return null

  const companyUrlTemplate = new URL(companyUrlFormat)
  // We only care about the path
  const templatePath = decodeURI(companyUrlTemplate.pathname)
  // We check the url path up until the position of the company slug
  const position = templatePath.indexOf('{{company.slug}}')
  if (position > -1) {
    const pathPrefix = templatePath.substring(
      0,
      position + '{{company.slug}}'.length,
    )
    // Replace all placeholders except {{company.slug}} with a regex wildcard
    const regexPattern = pathPrefix.replace(/{{(?!company\.slug).*?}}/g, '.*?')

    // Replace {{company.slug}} with a capturing group for slugs
    const finalPattern = regexPattern.replace(
      '{{company.slug}}',
      '([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*)',
    )

    // Create a RegExp object
    const regex = new RegExp(finalPattern)

    // Match the URL to extract the company slug
    const match = path.match(regex)
    return match ? match[1] : null
  } else {
    return null
  }
}

// TODO Replace with a block in storyblok that takes care of getting its own token from the queryparms instead of passing it down the context.
export const getJobAlertTokenFromPath = (path) => {
  const url = new URL(path, 'http://localhost') // We don't care about the origin
  if (url.pathname.includes('/unsubscribe')) {
    return url.searchParams.get('token')
  }

  return null
}
