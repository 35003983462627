export default function hydrateObject(
  obj,
  context,
  displayNotFoundPlaceholders,
) {
  // Base case: if not an object, return as is
  if (typeof obj !== 'object' || obj === null) {
    return obj
  }

  // If it's an array, process each element
  if (Array.isArray(obj)) {
    return obj.map((item) =>
      hydrateObject(item, context, displayNotFoundPlaceholders),
    )
  }

  // Process object properties
  return Object.keys(obj).reduce((result, key) => {
    const value = obj[key]

    if (typeof value === 'string') {
      // Check for complete {{...}} pattern
      const fullMatch = value.match(/^\{\{([^}]+)\}\}$/)
      if (fullMatch) {
        // Handle complete replacement for arrays/objects
        const path = fullMatch[1].trim().split('.')
        let replacement = getNestedValue(context, path)
        if (replacement === null) {
          replacement = displayNotFoundPlaceholders ? value : ''
        }
        result[key] = replacement
      } else {
        // Handle string interpolation
        result[key] = value.replace(/\{\{([^}]+)\}\}/g, (match, path) => {
          const parts = path.trim().split('.')
          const replacement = getNestedValue(context, parts)
          if (replacement === null) {
            return displayNotFoundPlaceholders ? match : ''
          }
          return replacement
        })
      }
      // If it's an object or array, process recursively
    } else if (typeof value === 'object' && value !== null) {
      result[key] = hydrateObject(value, context, displayNotFoundPlaceholders)
    }
    // Otherwise keep as is
    else {
      result[key] = value
    }

    return result
  }, {})
}

// Helper function to get a nested value from an object using a path array
function getNestedValue(obj, pathArray) {
  return pathArray.reduce((xs, x) => xs?.[x] ?? null, obj)
}
